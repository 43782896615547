.btn {
  appearance: none;
  border: none;
  border-radius: var(--rounded-lg);
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.btn:active,
.btn:focus {
  opacity: 0.75;
  box-shadow: -4px -4px 1px rgba(255, 255, 255, 0.3) inset;
  outline: 0;
}

.btn:disabled {
  background-color: var(--disabled);
  cursor: not-allowed;
  color: var(--black);
}

.btn:not(:disabled):hover {
  opacity: 0.75;
  box-shadow: 4px 4px rgba(255, 255, 255, 0.1);
}

.btn:not(:disabled):disabled:hover {
  box-shadow: none;
}

.btn-outline {
  border-width: var(--border-size);
  border-style: solid;
  background-color: transparent;
}

.btn-outline:disabled {
  background-color: transparent;
  border-color: var(--disabled);
  color: var(--disabled);
}

.btn-outline:disabled .text {
  color: var(--disabled);
}

.btn-ghost:not(:disabled):hover {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.btn-ghost:active,
.btn-ghost:focus {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.btn-ghost:disabled {
  background-color: var(--disabled);
  color: var(--black);
}

.btn-ghost:disabled .text {
  color: var(--black);
}

/* Sizes */

.btn.btn-xs {
  padding: 0 var(--spacing-2);
  line-height: var(--spacing-6);
  height: var(--spacing-6);
  min-width: var(--spacing-6);
  font-size: var(--font-xs);
}

.btn.btn-sm {
  padding: 0 var(--spacing-3);
  line-height: var(--spacing-8);
  height: var(--spacing-8);
  min-width: var(--spacing-8);
  font-size: var(--font-sm);
}

.btn {
  padding: 0 var(--spacing-4);
  line-height: var(--spacing-10);
  height: var(--spacing-10);
  min-width: var(--spacing-10);
  font-size: var(--font-md);
}

.btn.btn-lg {
  padding: 0 var(--spacing-6);
  line-height: var(--spacing-12);
  height: var(--spacing-12);
  min-width: var(--spacing-12);
  font-size: var(--font-lg);
}
