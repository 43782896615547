@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap');

:root {
  --font-xs: 0.75rem; /* 12px */
  --font-sm: 0.875rem; /* 14px */
  --font-md: 1rem; /* 16px */
  --font-lg: 1.125rem; /* 18px */
  --font-xl: 1.25rem; /* 20px */
  --font-2xl: 1.5rem; /* 24px */
  --font-3xl: 1.875rem; /* 30px */
  --font-4xl: 2.25rem; /* 36px */
  --font-5xl: 3rem; /* 48px */
  --font-6xl: 4rem; /* 64px */

  --line-height-xs: 1;
  --line-height-sm: 1.25;
  --line-height-md: 1.375;
  --line-height-lg: 1.5;
  --line-height-xl: 1.625;
  --line-height-2xl: 2;
}

.heading,
.select,
.input,
.text,
.btn {
  font-family: 'Fira Code', monospace;
}

.heading {
  margin: 0;
}

code {
  font-family: 'Fira Code', monospace;
  border-radius: var(--rounded-sm);
  background: var(--purple-100);
  color: var(--purple-900);
  padding: 0.1rem 0.3rem;
}

/* -- Heading -- */
.heading {
  font-size: var(--font-lg);
}

.heading-2xl {
  font-size: var(--font-5xl);
}

.heading-xl {
  font-size: var(--font-2xl);
}

.heading-lg {
  font-size: var(--font-xl);
}

.heading-sm {
  font-size: var(--font-md);
}

.heading-xs {
  font-size: var(--font-sm);
}

/* -- Text -- */

.text-bold {
  font-weight: bold;
}

.text-semibold {
  font-weight: 600;
}

/* -- Sizes -- */

.text {
  font-size: var(--font-md);
}

.text.text-lg {
  font-size: var(--font-lg);
}

.text.text-sm {
  font-size: var(--font-sm);
}

.text.text-xs {
  font-size: var(--font-xs);
}

/* -- Line Height -- */

.line-height {
  line-height: var(--line-height-md);
}

.line-height-2xl {
  line-height: var(--line-height-2xl);
}

.line-height-xl {
  line-height: var(--line-height-xl);
}

.line-height-lg {
  line-height: var(--line-height-lg);
}

.line-height-sm {
  line-height: var(--line-height-sm);
}

.line-height-xs {
  line-height: var(--line-height-xs);
}

/* -- Alignment -- */

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}