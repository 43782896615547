.input {
  border-radius: var(--rounded-lg);
  box-sizing: border-box;
  width: 100%;
  outline: none;
  background: var(--blackSecondary);
  border: 0.3rem solid transparent;
  transition: 0.3s ease-in-out all;
  -webkit-appearance: textfield;
}

.input:focus {
  border-color: var(--white);
}

.input::placeholder {
  color: var(--blackTernary);
}

.input-outline {
  background: transparent;
  border-color: var(--blackTernary);
}

@each $color in black, blackSecondary, white, cyan, green, orange, pink, purple,
  red, yellow
{
  .input-outline.input-$(color),
  .input-outline.input-$(color):focus,
  .input.input-$(color):focus {
    border-color: var(--$(color));
  }
}

/* Sizes */

.input.input-sm {
  padding: 0 var(--spacing-3);
  height: var(--spacing-8);
  font-size: var(--font-sm);
}

.input {
  padding: 0 var(--spacing-4);
  height: var(--spacing-10);
  font-size: var(--font-md);
}

.input.input-lg {
  padding: 0 var(--spacing-6);
  height: var(--spacing-12);
  font-size: var(--font-lg);
}

/* Border Sizes */

.input.input-border-sm {
  border-width: 0.1rem;
}

.input.input-border-md {
  border-width: 0.2rem;
}

.input.input-border-lg {
  border-width: 0.3rem;
}
